import { createApp } from 'vue'
import Whoops from './App.vue'

//import { BootstrapVue } from 'bootstrap-vue';
import "bootstrap/dist/css/bootstrap.min.css";
//import "bootstrap-vue/dist/css/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)

createApp(Whoops).mount('#app')
