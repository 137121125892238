<template>
  <NavButton text="Back" url="/"/>
  <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="text-center">
                    <h1>404</h1>
                    <p>Page not found</p>
                    <p>You aren't supposed to be here <a href="/">here's a way back home.</a></p>
                    <br>
                    <br>
                    <h2>Enjoy Benny</h2>
                    <img src="@/assets/images/benny.jpg" style="width:90%; max-width:500px; margin: 0 auto" alt="A picture of the cutest puppy">
                </div>
            </div>
        </div>
    </div>
 </template>

<script>
import NavButton from '@/components/NavButton.vue';

export default {
  name: '404Page',
  data: function() {
    return {
    }
  },
  components: {
    NavButton
  }
}
</script>

<style>
</style>

<style>
  @import '~/src/assets/styles/global.css';
</style>
<style>
  @import '~/src/assets/styles/variables.css';
</style>
