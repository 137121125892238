<template>
    <div class="Nav-Button">
        <!-- Todo back arrow, fa-->
        <a :href="url" class="btn btn-primary"><h1>{{ text }}</h1></a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'NavButton',
    props: {
      text: String,
        url: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  div {
    position: fixed;
    left: 30px;
    top: 30px;
    margin: 0 0;
    border-radius: 20px;
    color: white;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  h1 {
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 2rem;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: .1rem 0;
  }

  </style>

<style>
/* Lower container to duck under button */
@media screen and (max-width: 1680px){
      .container {
        padding-top: 120px !important;
      }
    }
</style>
  